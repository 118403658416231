<template>
  <div>
    <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
      :showButtons="panel.showButtons">
      <div slot="content-main">
        <div>
          <br />
          <b-tabs>
            <b-tab title="Categoria">
              <CategoryCustomer />
            </b-tab>
            <b-tab title="Origem">
              <OriginCustomer />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";

import CategoryCustomer from "./CategoryCustomer.vue";
import OriginCustomer from './OriginCustomer.vue'


import { mapMutations } from "vuex";

export default {
  name: "ConfiguracaoCrmView",
  components: { Panel, CategoryCustomer, OriginCustomer },
  data() {
    return {
      panel: {
        module: "CRM",
        title: "Configuração",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>
